export default {
  "mobile-app": "Logins via mobile apps",
  extension: {
    display: "hidden",
  },
  "verifiable-tls": {
    display: "hidden",
  },
  "verifiable-email": {
    display: "hidden",
  },
};
