import meta from "../../../pages/_meta.js";
import examples_meta from "../../../pages/examples/_meta.js";
import faqs_meta from "../../../pages/faqs/_meta.js";
import guides_meta from "../../../pages/guides/_meta.js";
import library_meta from "../../../pages/library/_meta.js";
import library_react_meta from "../../../pages/library/react/_meta.js";
import web_proofs_meta from "../../../pages/web-proofs/_meta.js";
export const pageMap = [{
  data: meta
}, {
  name: "404",
  route: "/404",
  frontMatter: {
    "sidebarTitle": "404"
  }
}, {
  name: "examples",
  route: "/examples",
  children: [{
    data: examples_meta
  }, {
    name: "extension",
    route: "/examples/extension",
    frontMatter: {
      "title": "Pluto Chrome Extension",
      "description": "Integrate Pluto's proof generation capabilities into your web applications using our Chrome extension."
    }
  }, {
    name: "mobile-app",
    route: "/examples/mobile-app",
    frontMatter: {
      "title": "Mobile TLS",
      "description": "Pluto brings accessible cryptography to all application developers."
    }
  }, {
    name: "verifiable-email",
    route: "/examples/verifiable-email",
    frontMatter: {
      "title": "Verifiable Email",
      "description": "Pluto brings accessible cryptography to all application developers."
    }
  }, {
    name: "verifiable-tls",
    route: "/examples/verifiable-tls",
    frontMatter: {
      "title": "Web Proofs over OAuth for delegated access",
      "description": "Pluto brings accessible cryptography to all application developers."
    }
  }]
}, {
  name: "faqs",
  route: "/faqs",
  children: [{
    data: faqs_meta
  }, {
    name: "index",
    route: "/faqs",
    frontMatter: {
      "title": "Frequently Asked Questions",
      "description": "Pluto is on a mission to solve the challenges of today’s blockchain applications using applied cryptography."
    }
  }, {
    name: "trust-assumptions",
    route: "/faqs/trust-assumptions",
    frontMatter: {
      "title": "Trust Assumptions",
      "description": "Pluto brings accessible cryptography to all application developers."
    }
  }, {
    name: "useful-links",
    route: "/faqs/useful-links",
    frontMatter: {
      "title": "Useful Links",
      "description": "Pluto brings accessible cryptography to all application developers."
    }
  }]
}, {
  name: "guides",
  route: "/guides",
  children: [{
    data: guides_meta
  }, {
    name: "authenticated-web-proofs",
    route: "/guides/authenticated-web-proofs",
    frontMatter: {
      "title": "Authenticated Web Proofs",
      "description": "Pluto brings accessible cryptography to all application developers."
    }
  }, {
    name: "full-reference",
    route: "/guides/full-reference",
    frontMatter: {
      "title": "Full Reference Web Proofs",
      "description": "Pluto brings accessible cryptography to all application developers."
    }
  }, {
    name: "http-extraction",
    route: "/guides/http-extraction",
    frontMatter: {
      "title": "HTTP Extraction Guide",
      "description": "Pluto brings accessible cryptography to all application developers."
    }
  }, {
    name: "quickstart",
    route: "/guides/quickstart",
    frontMatter: {
      "title": "Quickstart Guide",
      "description": "Pluto brings accessible cryptography to all application developers."
    }
  }]
}, {
  name: "index",
  route: "/",
  frontMatter: {
    "title": "Pluto Docs",
    "description": "Pluto is on a mission to solve the challenges of today's blockchain applications using applied cryptography"
  }
}, {
  name: "library",
  route: "/library",
  children: [{
    data: library_meta
  }, {
    name: "getting-started",
    route: "/library/getting-started",
    frontMatter: {
      "title": "Pluto Web Proofs Library",
      "description": "Pluto brings accessible cryptography to all application developers."
    }
  }, {
    name: "react",
    route: "/library/react",
    children: [{
      data: library_react_meta
    }, {
      name: "extractor",
      route: "/library/react/extractor",
      frontMatter: {
        "sidebarTitle": "Extractor"
      }
    }, {
      name: "prove",
      route: "/library/react/prove",
      frontMatter: {
        "title": "Pluto Web Proofs Library",
        "description": "Pluto brings accessible cryptography to all application developers."
      }
    }, {
      name: "verify",
      route: "/library/react/verify",
      frontMatter: {
        "title": "Pluto Web Proofs Library",
        "description": "Pluto brings accessible cryptography to all application developers."
      }
    }]
  }]
}, {
  name: "web-proofs",
  route: "/web-proofs",
  children: [{
    data: web_proofs_meta
  }, {
    name: "index",
    route: "/web-proofs",
    frontMatter: {
      "title": "Web Proofs",
      "description": "Pluto brings accessible cryptography to all application developers."
    }
  }, {
    name: "tls-notary",
    route: "/web-proofs/tls-notary",
    frontMatter: {
      "title": "TLSNotary",
      "description": "Pluto brings accessible cryptography to all application developers."
    }
  }]
}];