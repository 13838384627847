export default {
  "prove": "Prove",
  "verify": "Verify",
  "qr-code-generator": {
    "display": "hidden"
  },
  "install-pluto-extension": {
    "display": "hidden"
  },
  "extractor": {
    "display": "hidden"
  },
  "fetch-proof": {
    "display": "hidden"
  }
}