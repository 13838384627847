export default {
  "quickstart": {
    "display": "hidden"
  },
  "authenticated-web-proofs": {
    "display": "hidden"
  },
  "full-reference": {
    "display": "hidden"
  },
  "http-extraction": {
    "display": "hidden"
  }
}