export default {
  index: "Welcome",
  faqs: "FAQs",
  "web-proofs": "Web Proofs",
  examples: "Examples",
  guides: {
    display: "hidden",
  },
  library: {
    display: "hidden",
  },
  website: {
    title: "pluto.xyz ↗",
    type: "page",
    href: "https://pluto.xyz",
    newWindow: true,
  },
};
